<template>
  <div>
    <keep-alive>
      <router-view />
    </keep-alive>
    <van-tabbar
      v-model="active"
      route
      active-color="#4D77FD"
      inactive-color="#606266"
      placeholder
      z-index="98"
      safe-area-inset-bottom
      class="index"
    >
      <van-tabbar-item to="/bookcase">
        <span>书架</span>
        <template #icon="props">
          <img
            v-if="props.active == 0"
            src="../assets/images/tabBar/tab_icon1_def.png"
          />
          <img v-else src="../assets/images/tabBar/tab_icon1_sel.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/library">
        <span>书库</span>
        <template #icon="props">
          <img
            v-if="props.active == 0"
            src="../assets/images/tabBar/tab_icon2_def.png"
          />
          <img v-else src="../assets/images/tabBar/tab_icon2_sel.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/welfare">
        <span>福利</span>
        <template #icon="props">
          <img
            v-if="props.active == 0"
            src="../assets/images/tabBar/tab_icon3_def.png"
          />
          <img v-else src="../assets/images/tabBar/tab_icon3_sel.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/my">
        <span>我的</span>
        <template #icon="props">
          <img
            v-if="props.active == 0"
            src="../assets/images/tabBar/tab_icon4_def.png"
          />
          <img v-else src="../assets/images/tabBar/tab_icon4_sel.png" />
        </template>
      </van-tabbar-item>
    </van-tabbar>

     <van-popup v-model="show" position="center" class="popo_style">
      请你务必审慎阅读、充分理解"用户协议"和"隐私政策"各条款,包括但不限于:为了更好的向你提供服务,我们需要收集你的设备标识、操作日志等信息用于分析、优化应用性能。
      <br />你可阅读 <span @click="user_xieyi">《用户协议》 </span>和
      <span @click="privacy_xieyi">《隐私政策》</span>了解详细信息。<br />

      <van-button
        plain
        type="info"
        @click="agree"
        :style="{ 'margin-left': '50px', top: '20px' }"
        >同意</van-button
      >

      <van-button
        plain
        type="info"
        @click="refuse"
        :style="{ 'margin-left': '50px', top: '20px' }"
        >拒绝</van-button
      >
      <!--
          <Pay :chapterList='chapterList' :readList="readList" @payList="payList" @topupClick="topupClick"
           @buttonClick="buttonClick"></Pay>
      -->
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      active: 0,
      show: false,
    };
  },
  mounted() {
    if (window.localStorage.getItem("IsFirstStart") == null) {
      // this.show = true;
    } else {
    }
  },
  methods: {
    user_xieyi() {
      this.$router.push({
        path: "/agreement",
      });
    },
    privacy_xieyi() {
      this.$router.push({
        path: "/privacy",
      });
    },
    agree() {
      window.localStorage.setItem("IsFirstStart", "true");
      this.show = false;
    },
    refuse() {
      system.postMessage("quit");
    },
  },
};
</script>

<style scoped>
.index {
  /*margin-top: 40px;*/
}

.popo_style {
  width: 80%;
  padding: 4rem 1rem 4rem 1rem;
  border-radius: 0.8rem;
  line-height: 1.3rem;
  font-size: 0.9rem;
}
.popo_style span {
  color: #4d77fd;
}
</style>